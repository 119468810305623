import AppGlobalsContext from "app/AppGlobalsContext";
import Button from "app/pages/.shared/form/Button";
import FormErrorMessages from "app/pages/.shared/form/FormErrorMessages/FormErrorMessages";
import IconClose from "app/pages/.shared/IconClose";
import IconDeparting from "app/pages/.shared/IconDeparting";
import IconLocation from "app/pages/.shared/IconLocation";
import IconMagnifyingGlass from "app/pages/.shared/IconMagnifyingGlass";
import OccupanciesDisplayLabel from "app/pages/.shared/OccupanciesDisplayLabel/OccupanciesDisplayLabel";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import ClearIndicatorSdpSearchInput from "app/pages/SmartDP/Search/SDPSearchForm/ClearIndicatorSdpSearchInput";
import DestinationInputValueFormat from "app/pages/SmartDP/Search/SDPSearchForm/DestinationInputValueFormat";
import LocationMenuList from "app/pages/SmartDP/Search/SDPSearchForm/LocationMenuList";
import SmartDPDateCalendarContainer from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPDateCalendarContainer";
import {
	defaultValues,
	validateDepartureCity,
	validateDepartureDate,
	validateDestination,
	validateSearch,
} from "app/pages/SmartDP/Search/SDPSearchForm/smartDPSearchFormSchema";
import SmartDPSearchInput from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchInput";
import TravellersRoomButton from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomButton";
import { destinationResortFilterOptions, isServerSide, sortOptions } from "app/utils/utils";
import classNames from "classnames";
import { format } from "date-fns";
import { Form, Formik } from "formik";
import { isEmpty, orderBy } from "lodash";
import get from "lodash/get";
import PropTypes from "prop-types";
import { memo, useCallback, useContext, useMemo, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useMatch } from "react-router-dom";
import "./SmartDPSearchForm.scss";
import { DESTINATION_USA_CODE, FS_QUOTE_EVENT_NAME, SDP_ROTATION_DATE_FORMAT } from "app/constants";
import CalendarDisabledView from "app/pages/SmartDP/Search/SDPSearchForm/CalendarDisabledView";
import TravellersRoomDrawerButton from "app/pages/SmartDP/Search/TravellersRoomInput/TravellersRoomDrawerButton";
import { EventCategory, HitType, useFlagship } from "@flagship.io/react-sdk";
import { brandPropTypes } from "app/utils/propTypes";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import InputValueFormat from "app/pages/SmartDP/Search/SDPSearchForm/InputValueFormat";

const SmartDPSearchForm = ({
	onSuccess = () => {},
	departureCities = [],
	destinations = [],
	cabins = [],
	initialValues = {},
	hideSidePanel = () => {},
	topDestinationsList = [],
	showMiniSDPForm = false,
	validateOnMount,
	displayLoader,
	errorScrollIntoView = true,
	onFieldFocus = () => {},
	isAutoScrollActive,
	resetAllSDPProductsFilter = () => {},
}) => {
	const { hit: fsHit } = useFlagship();

	const selectRefs = {
		departureCity: useRef(null),
		destinationResort: useRef(null),
		departureDate: useRef(null),
		cabin: useRef(null),
	};

	const [isTopDepartureCitiesMenuDisplayed, setIsTopDepartureCitiesMenuDisplayed] = useState(
		true
	);
	const [isTopDestinationsMenuDisplayed, setIsTopDestinationsMenuDisplayed] = useState(true);

	const { pathname } = useLocation();
	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const handleSubmit = useCallback(
		(values, actions) => {
			resetAllSDPProductsFilter();
			actions.setSubmitting(false);
			onSuccess({ values, pathname });
		},
		[departureCities, destinations, cabins, pathname]
	);
	const [destinationInput, setDestinationInput] = useState();
	// fix pour ne pas avoir les labels sur 2 lignes au premier rendu
	const mergeInitialValues = isServerSide
		? {
				occupancies: [{ adults: 2, children: 0, childrenBirthdates: [] }],
		  }
		: {
				...defaultValues,
				...initialValues,
		  };

	const isListingOrQuote = pathname.includes("/sdp/listing") || pathname === "/sdp/booking/quote";
	const isMerchPage = pathname.includes("/merch");

	const isSDPSearchPage = useMatch({
		path: "/sdp/search",
		end: true,
		caseSensitive: true,
	});
	const isSDPRootPage = useMatch({
		path: "/",
		end: true,
		caseSensitive: true,
	});

	const isSDPHomePage = isSDPRootPage || isSDPSearchPage;
	const isSticky =
		!isMobile && ["/sdp/listing", "/sdp/booking/quote"].some(path => pathname.includes(path));

	const hasCabins = isServerSide || cabins.length > 0;

	const topDepartureCities = orderBy(
		departureCities.filter(option => option?.isMain),
		["mainOrder"],
		["asc"]
	);
	const onHandleClickSearchButton = useCallback(() => {
		fsHit.send({
			type: HitType.EVENT,
			category: EventCategory.ACTION_TRACKING,
			action: FS_QUOTE_EVENT_NAME.CLICK_ON_SEARCH_CTA,
		});
	}, []);

	const popoverValues = useMemo(() => {
		let values = {
			travelRoomsPopoverWidth: 0,
			travelRoomsPopoverOffset: [0, 0],
			departurePopoverWidth: 0,
			departurePopoverOffset: [-1, 0],
			calendarPopoverOffset: [0, 0],
			destinationPopoverWidth: 0,
			calendarPopoverWidth: 1024,
			cabinPopoverWidth: 0,
		};

		if (hasCabins) {
			values.departurePopoverOffset = isListingOrQuote ? [-1, 0] : [-17, -4];

			if (isListingOrQuote) {
				// Cas: hasCabins && isListingOrQuote
				values = {
					...values,
					travelRoomsPopoverWidth: 230,
					travelRoomsPopoverOffset: [30, 11],
					departurePopoverWidth: 450,
					destinationPopoverWidth: 450,
					calendarPopoverOffset: [0, -9],
					cabinPopoverWidth: 290,
				};
			} else {
				// Cas: hasCabins && !isListingOrQuote
				values = {
					...values,
					travelRoomsPopoverWidth: 314,
					travelRoomsPopoverOffset: [71, 8],
					departurePopoverWidth: 450,
					destinationPopoverWidth: 450,
					calendarPopoverOffset: [0, -9],
					cabinPopoverWidth: 285,
				};
			}
		} else if (isListingOrQuote) {
			// Cas: !hasCabins && isListingOrQuote
			values = {
				...values,
				travelRoomsPopoverWidth: 250,
				travelRoomsPopoverOffset: [30, 11],
				departurePopoverWidth: 450,
				destinationPopoverWidth: 450,
				calendarPopoverOffset: [0, -9],
				cabinPopoverWidth: 285,
			};
		} else {
			values.departurePopoverOffset = [-17, -6];
			// Cas: !hasCabins && !isListingOrQuote
			values = {
				...values,
				travelRoomsPopoverWidth: 327,
				travelRoomsPopoverOffset: [80, 6],
				departurePopoverWidth: 450,
				destinationPopoverWidth: 450,
				calendarPopoverOffset: [0, -9],
				cabinPopoverWidth: 285,
			};
		}

		return values;
	}, [hasCabins, isListingOrQuote]);

	const [refs, setRefs] = useState(null);

	const updateFloatingPositionReference = useCallback(refs => {
		setRefs(refs);
	}, []);
	const customFieldIds = ["departureDate"];

	const fieldIds = useMemo(() => ["departureCity", "destinationResort", "departureDate"], []);

	const fieldOrder = useMemo(() => fieldIds.map(id => ({ id, ref: selectRefs[id] })), [
		fieldIds,
		selectRefs,
	]);
	const getFieldValue = (values, fieldId) => {
		const nestedFields = {
			departureDate: ["travelDates", "departureDate"],
		};

		if (nestedFields[fieldId]) {
			return nestedFields[fieldId].reduce(
				(acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
				values
			);
		}

		return values[fieldId];
	};

	const handleFieldChange = useCallback(
		(fieldId, setFieldValue, value, values) => {
			setFieldValue(fieldId, value);

			if (value) {
				const currentIndex = fieldOrder.findIndex(field => field.id === fieldId);
				if (currentIndex === -1) {
					return;
				}

				const previousFields = fieldOrder.slice(0, currentIndex);
				const firstEmptyPrevField = previousFields.find(
					field => !getFieldValue(values, field.id)
				);

				if (firstEmptyPrevField && firstEmptyPrevField.ref.current) {
					if (customFieldIds.includes(firstEmptyPrevField.id)) {
						requestAnimationFrame(() => {
							firstEmptyPrevField.ref.current.click();
						});
					} else {
						requestAnimationFrame(() => {
							firstEmptyPrevField.ref.current.focus();
						});
					}
					return;
				}

				let nextIndex = currentIndex + 1;

				while (nextIndex < fieldOrder.length) {
					const nextField = fieldOrder[nextIndex];
					if (!getFieldValue(values, nextField.id) && nextField.ref.current) {
						if (customFieldIds.includes(nextField.id)) {
							requestAnimationFrame(() => {
								nextField.ref.current.click();
							});
						} else {
							requestAnimationFrame(() => {
								nextField.ref.current.focus();
							});
						}
						break;
					}
					nextIndex++;
				}
			}
		},
		[fieldOrder, customFieldIds]
	);

	return (
		<div className="sdp-search-form" ref={refs?.setPositionReference}>
			{(isListingOrQuote || ((isSDPHomePage || isMerchPage) && showMiniSDPForm)) && isMobile && (
				<header className="sdp-search-form__header">
					<Typography variant={TYPOGRAPHY_VARIANTS.LARGE} isBold component="h3">
						<FormattedMessage id="sdp.search.home.input.label" />
					</Typography>
					<div className="advanced-select__header-icon">
						<IconClose width={24} height={24} onClick={hideSidePanel} />
					</div>
				</header>
			)}
			<Formik
				enableReinitialize
				initialValues={mergeInitialValues}
				validate={validateSearch(false)}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={handleSubmit}
				initialTouched={
					validateOnMount && {
						departureCity: true,
						destinationResort: true,
						travelDates: {
							departureDate: true,
							endDate: true,
						},
						duration: {
							code: true,
						},
						occupancies: true,
						cabin: true,
					}
				}
				validateOnMount={validateOnMount}
			>
				{({ values, errors, isValid, isSubmitting, setFieldValue, submitCount }) => {
					const handleTravellersConfirmation = values => {
						setFieldValue("occupancies", values);
					};

					const selectedDestinationData = destinations.find(destination => {
						// can be string if from query or number if from filters.json
						return String(destination.code) === String(values?.destinationResort?.code);
					});

					const sdpRotationUri = selectedDestinationData?.departureCities?.find(
						city => city?.code === values?.departureCity?.code
					)?.uri;

					const isUsaDestination = DESTINATION_USA_CODE.includes(
						values?.destinationResort?.code
					);

					return (
						<>
							<Form className="sdp-search-form__form" data-testid="sdp-search-form">
								<SmartDPSearchInput
									id="departureCity"
									name="departureCity"
									selectRef={selectRefs.departureCity}
									openMenuOnFocus={!values.departureCity}
									openMenuOnClick={!values.departureCity}
									onChange={value =>
										handleFieldChange(
											"departureCity",
											setFieldValue,
											value,
											values
										)
									}
									className="sdp-search-form__field sdp-search-form__field-departure"
									data-testid="departure-city-input"
									onFocus={onFieldFocus}
									isAutoScrollActive={isAutoScrollActive}
									validate={validateDepartureCity}
									label={
										<FormattedMessage id="sdp.search.departure.city.input.label" />
									}
									drawerInputLabel={
										<FormattedMessage id="sdp.search.departure.city.input.label.mobile" />
									}
									components={{
										SingleValue: InputValueFormat,
										MenuList: LocationMenuList,
										ClearIndicator: ClearIndicatorSdpSearchInput,
									}}
									formatOptionLabel={({ label = "" }) => {
										return (
											<div className="sdp-search-form__suggestion">
												{!isMobile && (
													<div className="sdp-search-form__suggestion-picto">
														<IconDeparting />
													</div>
												)}
												<span
													className="sdp-search-form__suggestion-label"
													dangerouslySetInnerHTML={{
														__html: label,
													}}
												/>
											</div>
										);
									}}
									getOptionValue={({ code }) => code}
									getOptionLabel={({ label }) => label}
									options={
										isTopDepartureCitiesMenuDisplayed
											? topDepartureCities
											: departureCities
									}
									// on a ajouté  {|| "" } dans value de l'input pour forcer l'initialisation du champ s'il reçoit rien comme
									// valeur par defaut
									value={
										departureCities.find(({ code }) => {
											return code === get(values, "departureCity.code");
										}) || ""
									}
									noOptionsMessage={() => {
										return <FormattedMessage id="sdp.search.no.result.label" />;
									}}
									popperWidth={popoverValues.departurePopoverWidth}
									popperOffset={popoverValues.departurePopoverOffset}
									locationMenuLabel={
										<FormattedMessage id="sdp.search.top.departure.cities.label" />
									}
									isTopLocationMenuDisplayed={isTopDepartureCitiesMenuDisplayed}
									onInputChange={inputValue => {
										if (isEmpty(inputValue)) {
											if (!isTopDepartureCitiesMenuDisplayed) {
												setIsTopDepartureCitiesMenuDisplayed(true);
											}
										} else if (isTopDepartureCitiesMenuDisplayed) {
											setIsTopDepartureCitiesMenuDisplayed(false);
										}
									}}
									loading={displayLoader}
								/>

								<SmartDPSearchInput
									id="destinationResort"
									name="destinationResort"
									selectRef={selectRefs.destinationResort}
									openMenuOnFocus={!values.destinationResort}
									openMenuOnClick={!values.destinationResort}
									validate={validateDestination}
									onChange={value =>
										handleFieldChange(
											"destinationResort",
											setFieldValue,
											value,
											values
										)
									}
									className="sdp-search-form__field sdp-search-form__field-destination"
									data-testid="destination-resort-input"
									onFocus={onFieldFocus}
									isAutoScrollActive={isAutoScrollActive}
									filterOption={
										isTopDestinationsMenuDisplayed
											? undefined
											: destinationResortFilterOptions
									}
									label={
										<Typography isBold>
											<FormattedMessage id="sdp.search.destination.input.label" />
										</Typography>
									}
									drawerInputLabel={
										<FormattedMessage id="sdp.search.destination.input.label.mobile" />
									}
									formatOptionLabel={({ labels = [] }) => {
										return (
											<div className="sdp-search-form__suggestion">
												<div className="sdp-search-form__suggestion-picto">
													<IconLocation />
												</div>
												<div className="sdp-search-form__suggestion-text">
													<span
														className="sdp-search-form__suggestion-first-label"
														dangerouslySetInnerHTML={{
															__html: labels?.slice(-1).join(", "),
														}}
													/>
													<span
														className="sdp-search-form__suggestion-second-label"
														dangerouslySetInnerHTML={{
															__html: labels?.slice(0, -1).join(", "),
														}}
													/>
												</div>
											</div>
										);
									}}
									components={{
										SingleValue: DestinationInputValueFormat,
										ClearIndicator: ClearIndicatorSdpSearchInput,
										MenuList: LocationMenuList,
									}}
									getOptionValue={({ code }) => code}
									getOptionLabel={({ label }) => label}
									options={
										isTopDestinationsMenuDisplayed
											? topDestinationsList
											: sortOptions(destinations, destinationInput)
									}
									// on a ajouté  {|| "" } dans value de l'input pour forcer l'initialisation du champ s'il reçoit rien comme
									// valeur par defaut
									value={
										destinations.find(({ code }) => {
											return (
												code ===
												Number(get(values, "destinationResort.code"))
											);
										}) || ""
									}
									noOptionsMessage={() => {
										return <FormattedMessage id="sdp.search.no.result.label" />;
									}}
									popperWidth={popoverValues.destinationPopoverWidth}
									locationMenuLabel={
										<FormattedMessage id="sdp.search.top.destinations.cities.label" />
									}
									isTopLocationMenuDisplayed={isTopDestinationsMenuDisplayed}
									onInputChange={inputValue => {
										if (isEmpty(inputValue)) {
											if (!isTopDestinationsMenuDisplayed) {
												setIsTopDestinationsMenuDisplayed(true);
											}
										} else if (isTopDestinationsMenuDisplayed) {
											setIsTopDestinationsMenuDisplayed(false);
										}
										setDestinationInput(inputValue);
									}}
									loading={displayLoader}
								/>

								<SmartDPDateCalendarContainer
									id="travelDates"
									name="travelDates"
									data-testid="travel-date-input"
									departureDateRef={selectRefs.departureDate}
									endDateRef={selectRefs.endDate}
									validate={validateDepartureDate}
									onFocus={onFieldFocus}
									isAutoScrollActive={isAutoScrollActive}
									className="sdp-search-form__field"
									popperOffset={popoverValues.calendarPopoverOffset}
									updateFloatingPositionReference={
										updateFloatingPositionReference
									}
									{...isListingOrQuote && {
										popoverWidth: popoverValues.calendarPopoverWidth,
									}}
									calendarDisabledView={
										<CalendarDisabledView
											mainMessage={
												values.departureCity && values.destinationResort ? (
													<FormattedMessage id="sdp.calendar.unavailable.date.label" />
												) : (
													<FormattedMessage id="sdp.calendar.no.destination.no.departure.city.label" />
												)
											}
											secondaryMessage={
												values.departureCity && values.destinationResort ? (
													<FormattedMessage id="sdp.calendar.unavailable.date.sublabel" />
												) : null
											}
										/>
									}
									uri={sdpRotationUri}
									handleDayChange={({
										durationsOfSelectedDepartureDate = [],
										selectedEndDate,
									}) => {
										// durationsOfSelectedDepartureDate [{duration, ed}]

										if (isEmpty(durationsOfSelectedDepartureDate)) {
											setFieldValue("duration", {
												code: "",
											});
										} else if (selectedEndDate) {
											const formattedSelectedEndDate = format(
												new Date(selectedEndDate),
												SDP_ROTATION_DATE_FORMAT
											);

											const dataOfSelectedDepartureDate = durationsOfSelectedDepartureDate.find(
												data => data.ed === formattedSelectedEndDate
											);
											if (dataOfSelectedDepartureDate) {
												setFieldValue("duration", {
													code: dataOfSelectedDepartureDate.duration,
												});
											} else {
												setFieldValue("duration", {
													code: "",
												});
											}
										} else {
											setFieldValue("duration", {
												code: "",
											});
										}
									}}
									loading={displayLoader}
								/>

								{hasCabins && (
									<SmartDPSearchInput
										id="cabin"
										name="cabin"
										selectRef={selectRefs.cabin}
										openMenuOnFocus
										onFocus={onFieldFocus}
										isAutoScrollActive={isAutoScrollActive}
										className="sdp-search-form__field sdp-search-form__field-cabin"
										data-testid="cabin-input"
										isSearchable={false}
										label={
											<FormattedMessage id="sdp.search.cabin.input.label" />
										}
										getOptionLabel={({ label = "", suffix = "" }) =>
											`${label}${suffix ? `, ${suffix}` : ""}`
										}
										components={{
											SingleValue: InputValueFormat,
											ClearIndicator: ClearIndicatorSdpSearchInput,
										}}
										getOptionValue={({ code }) => code}
										options={cabins}
										value={cabins.find(({ code }) => {
											return code === get(values, "cabin.code");
										})}
										popperWidth={popoverValues.cabinPopoverWidth}
										popperOffset={[0, 0]}
										loading={displayLoader}
									/>
								)}

								{isMobile ? (
									<TravellersRoomDrawerButton
										className={classNames(
											"sdp-search-form__field sdp-search-form__field-rooms",
											{
												"sdp-search-form__field--fullsize": !hasCabins,
											}
										)}
										id="travellersRooms"
										valueToDisplay={
											<OccupanciesDisplayLabel
												occupancies={values.occupancies}
											/>
										}
										onConfirmation={handleTravellersConfirmation}
										initialValues={values.occupancies}
										label={
											<FormattedMessage id="sdp.search.travellers.input.label" />
										}
										validateOnMount={validateOnMount}
										warningMessage={isUsaDestination}
									/>
								) : (
									<TravellersRoomButton
										className={classNames(
											"sdp-search-form__field sdp-search-form__field-rooms",
											{
												"sdp-search-form__field--fullsize": !hasCabins,
											}
										)}
										id="travellersRooms"
										onFocus={onFieldFocus}
										isAutoScrollActive={isAutoScrollActive}
										valueToDisplay={
											<OccupanciesDisplayLabel
												occupancies={values.occupancies}
											/>
										}
										onConfirmation={handleTravellersConfirmation}
										initialValues={values.occupancies}
										label={
											<FormattedMessage id="sdp.search.travellers.input.label" />
										}
										stickyMode={isSticky}
										validateOnMount={validateOnMount}
										loading={displayLoader}
										popperWidth={popoverValues.travelRoomsPopoverWidth}
										popperOffset={popoverValues.travelRoomsPopoverOffset}
										warningMessage={isUsaDestination}
									/>
								)}

								<div className="sdp-search-form__footer">
									{isListingOrQuote && !isMobile ? (
										<Button
											variant="primary"
											submit
											className="sdp-search-form__button"
											loading={isSubmitting}
											data-testid="sdp-search-form-button"
											disabled={displayLoader || isSubmitting}
										>
											<IconMagnifyingGlass />
										</Button>
									) : (
										<Button
											variant="primary"
											submit
											className="sdp-search-form__button"
											loading={isSubmitting}
											data-testid="sdp-search-form-button"
											onClick={onHandleClickSearchButton}
											disabled={displayLoader || isSubmitting}
										>
											<FormattedMessage id="sdp.search.cta.label" />
										</Button>
									)}
								</div>
							</Form>
							{/* Do not display error message on validationOnMount (redirection from emirates) */}
							{!isValid && submitCount >= 1 && (
								<div className="sdp-search-form__error">
									<FormErrorMessages
										errors={errors}
										isSubmitting={isSubmitting}
										submitCount={submitCount}
										disableErrorsDetails={true}
										message={
											<FormattedMessage id="sdp.search.form.error.message" />
										}
										scrollInToView={errorScrollIntoView}
									/>
								</div>
							)}
						</>
					);
				}}
			</Formik>
		</div>
	);
};

SmartDPSearchForm.propTypes = {
	onSuccess: PropTypes.func,
	departureCities: PropTypes.array,
	destinations: PropTypes.array,
	cabins: PropTypes.array,
	initialValues: PropTypes.object,
	hideSidePanel: PropTypes.func,
	topDestinationsList: PropTypes.array,
	showMiniSDPForm: PropTypes.bool,
	validateOnMount: PropTypes.bool,
	handleFormError: PropTypes.func,
	onFieldFocus: PropTypes.func,
	isAutoScrollActive: PropTypes.bool,
	displayLoader: PropTypes.bool,
	brand: brandPropTypes,
	errorScrollIntoView: PropTypes.bool,
	resetAllSDPProductsFilter: PropTypes.func,
};

export default memo(SmartDPSearchForm);
